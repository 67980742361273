var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "card",
        {
          attrs: { title: _vm.$t("models.variation.selves") },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", small: "" },
                      on: {
                        click: function ($event) {
                          _vm.importDialog = true
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "", left: "" } }, [
                        _vm._v("fa fa-file-excel"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.$t("Import a config")) + " "),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("grid-view", {
            ref: "grid",
            attrs: {
              headers: _vm.headers,
              endpoint: "variation?expand=sector,country",
              model: "variation",
            },
            scopedSlots: _vm._u([
              {
                key: "item._actions",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.exportModel(item)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { "x-small": "" } }, [
                          _vm._v("fa fa-download"),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.deleteModel(item)
                          },
                        },
                      },
                      [
                        _c("v-icon", { attrs: { "x-small": "" } }, [
                          _vm._v("fa fa-trash"),
                        ]),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "item.sector",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(" " + _vm._s(_vm.translate(item.sector.name)) + " "),
                  ]
                },
              },
              {
                key: "item.country",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.country && item.country.name
                            ? _vm.translate(item.country.name)
                            : "N/A"
                        ) +
                        " "
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "600" },
          model: {
            value: _vm.importDialog,
            callback: function ($$v) {
              _vm.importDialog = $$v
            },
            expression: "importDialog",
          },
        },
        [
          _vm.importDialog
            ? _c("import-config", {
                on: {
                  done: function ($event) {
                    return _vm.configImported()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }